
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import toast from 'react-hot-toast'

import NoteBox from 'components/NoteBox'
import ActionFeed from 'components/ActionFeed'
import BulkOptions from 'components/BulkOptions'
import ExplorationDetails from 'components/ExplorationDetails'

import EditAdminForm from './EditAdminForm'
import TableCodes from './TableCodes'
import TitleBox from './TitleBox'
import TitleForm from './TitleForm'
import CollectorTable from './CollectorTable'
import EditCollectorTable from './EditCollectorTable'
import { transformData } from 'views/Share/utils'
import { detailOptions } from './detailTerritories'

import { adminEdit } from 'api/share'
import { getSelectedSharesFile as exportSelected } from 'api/download'

import Dragula from 'react-dragula'

const PLATFORMS = [
  { value: 'youtube', label: 'YouTube' },
  { value: 'mri', label: 'MRI' },
  { value: 'hfa', label: 'HFA' },
  { value: 'mlc', label: 'MLC' },
  { value: 'cmrra', label: 'CMRRA' },
  { value: 'ascap', label: 'ASCAP' },
  { value: 'bmi', label: 'BMI' },
  { value: 'sesac', label: 'SESAC' },
  { value: 'soundexchange', label: 'SoundExchange' },
  { value: 'distribution', label: 'Distribution' },
  { value: 'sub-publishing', label: 'Sub-Publishing' },
  { value: 'lyricfind', label: 'LyricFind' },
  { value: 'lyricfindmerch', label: 'LyricFindMerch' },
  { value: 'direct licensing', label: 'Direct Licensing' },
  { value: 'sync', label: 'Sync' }
]

const AdminDetail = ({ record, setRecord, authStore, user }) => {
  const { id } = useParams()
  const history = useHistory()

  const [showTable, setShowTable] = useState(true)
  const [showEditForm, setShowEditForm] = useState(false)
  const [showOwnershipForm, setShowOwnershipForm] = useState(false)
  const [showTitleEditForm, setShowTitleEditForm] = useState(false)
  const [toggleExport, setToggleExport] = useState(false)
  const [currNav, setCurrNav] = useState('Ownerships')
  const [territory, setTerritory] = useState('WW')
  const [rows, setRows] = useState([])

  let initialValues = {}
  if (record !== undefined) {
    let recordCopy = Object.assign({}, record)
    initialValues = transformData(recordCopy)
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const _handleAdminSubmit = (values) => {
    adminEdit(values, id).then(res => {
      if (res.status === 200) {
        setRecord(res.data)
        let responseCopy = Object.assign({}, res.data)
        methods.reset(transformData(responseCopy))
        setShowEditForm(false)
        setShowOwnershipForm(false)
        setShowTitleEditForm(false)
      } else {
        toast.error('Unable to update share')
      }
    }).catch(err=>{
      console.log(err)
      console.log(err.response)
      toast.error('Unable to update share')
    })
    setShowTitleEditForm(false)
  }

  const formatData = (values, count) => {
    let data = Object.assign({}, values)

    data.ordering = rows
    data.toggle = false
    const shareTypes = ['sr', 'mr', 'pr', 'lr']

    data.collectors = data.collectors.map((collector, idx) => {
      return collector.pk
    })

    data.collector_role_code = data.collector_role_code?.value

    data.publishers = data.publishers.map((publisher) => {
      let pub = Object.assign({}, publisher)
      pub.owners = pub.owners.map((owner) => {
        return owner.pk
      })
      pub.owner_role_code = pub.owner_role_code.value;
      pub.ownership_writers = pub.ownership_writers.map((writer) => {
        if (writer.create === undefined) {
          if (writer.writer_role_code !== undefined) {
            return { writer: writer.writer.pk, writer_role_code: writer.writer_role_code.value }
          } else {
            return { writer: writer.writer.pk, writer_role_code: '' }
          }
        } else {
          if (writer.writer_role_code !== undefined) {
            writer.writer_role_code = writer.writer_role_code.value;
            return writer;
          } else {
            writer.writer_role_code = ''
            return writer;
          }
        }
      })

      if (!pub['lr_share']) {
        pub['lr_share'] = 0
      }

      shareTypes.map((type) => {
        if (pub[type + '_share_territory'] !== undefined) {
          if (Array.isArray(pub[type + '_share_territory'])) {
            pub[type + '_share_territory'] = (pub[type + '_share_territory'].map((terr) => { return terr.value })).join(',')
          } else {
            pub[type + '_share_territory'] = pub[type + '_share_territory']['value']
          }
        }
      })
      return pub
    })

    if (data.writers !== undefined) {
      data.writers = data.writers.map((w, idx) => {
        let writer = Object.assign({}, w)
        writer.owners = writer.owners.map((owner, idx) => {
          return owner.pk
        })
        writer.owner_role_code = writer.owner_role_code.value
        if (writer.ownership_writers !== undefined) {
          writer.ownership_writers = writer.ownership_writers.map((writer) => {
            if (writer.writer_role_code !== undefined) {
              return { writer: writer.writer.pk, writer_role_code: writer.writer_role_code.value }
            } else {
              return { writer: writer.writer.pk, writer_role_code: '' }
            }
          })
        }

        if (!writer['lr_share']) {
          writer['lr_share'] = 0
        }

        shareTypes.map((type) => {
          if (writer[type + '_share_territory'] !== undefined) {
            if (Array.isArray(writer[type + '_share_territory'])) {
              writer[type + '_share_territory'] = (writer[type + '_share_territory'].map((terr) => { return terr.value })).join(',')
            } else {
              writer[type + '_share_territory'] = writer[type + '_share_territory']['value']
            }
          }
        })
        return writer
      })
    }

    _handleAdminSubmit(data)
  }

  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      var options = {
        moves: function (el, container, handle) {
          return handle.nodeName === 'svg' || handle.nodeName === 'ION-ICON';
        }
      };

      var drake = Dragula([componentBackingInstance], options);

      drake.on('drop', (el, target) => {
        const childNodes = target['childNodes'];
        let rows = [];
        for (var i = 0; i < childNodes.length; i++) {
          let entry = {
            'id': childNodes[i]['id'],
            'order': i
          };
          rows[i] = entry;
        }
        setRows(rows);
      })
    }
  }

  const getExport = (typer) => {
    var data = { selected: [record.pk], typer: typer }
    exportSelected(data).then(res => {
      if (res.status === 200) {
        history.push('/download/my-exports/?date=' + res.data.date)
      } else {
        toast.error('Unable to get export')
      }
    }).catch(err=>{
      console.log(err.response)
      toast.error('Unable to get export')
    })
  }


  const _toggleEdit = (form) => {
    if (form === 'admin') {
      setShowEditForm(!showEditForm)
    }
  }

  const _toggleTitleEdit = (form) => {
    if (form === 'adminTitle') {
      setShowTitleEditForm(!showTitleEditForm)
    }
  }

  const _toggleOwnershipEdit = () => {
    setShowOwnershipForm(!showOwnershipForm)
  }

  const formatLabel = (option, elem) => {
    if (elem.context === 'menu') {
      return (
        <div className='select-option'>
          <span className='select-title'><ion-icon name={option.isPublisher ? 'contacts' : 'person'} />&nbsp;{option.label}</span>
          <span className='select-muted'>{option.isPublisher ? 'Publisher' : 'Writer'} ID: {option.id ? option.id : 'N/A'}</span>
        </div>
      );
    } else {
      return (
        <div className='select-option'>
          <span className='select-title'><ion-icon name={option.isPublisher ? 'contacts' : 'person'} />&nbsp;{option.label}</span>
        </div>
      );
    }
  }

  const email = user.email

  const profile = user.profile
  const profilePublishers = []

  const userTerritoryPresets = user.user_territory_presets
  const option = {};
  option['AD'] = 'Adaptor';
  option['AM'] = 'Administrator';
  option['AR'] = 'Arranger';
  option['PR'] = 'Associated Performer';
  option['A'] = 'Author';
  option['C'] = 'Composer';
  option['CA'] = 'Composer/Author';
  option['E'] = 'Original Publisher';
  option['PA'] = 'Publisher Income Participant';
  option['SR'] = 'Sub-Arranger';
  option['SA'] = 'Sub-Author';
  option['SE'] = 'Sub-Publisher';
  option['ES'] = 'Substitute Publisher';
  option['TR'] = 'Translator';
  option['W'] = 'Writer';
  option['PY'] = 'Payee';

  if (profile !== undefined && profile !== null) {
    for (var i = 0; i < profile.publisher.length; i++) {
      profilePublishers[i] = profile.publisher[i].pk
    }
  }
  var ownerships = record.ownerships
  var administrators = record.administrators
  var relatedTerritories = ''
  var excludeSets = []
  if (ownerships) {
    ownerships.map((item) => {
      if (item.pr_include_exclude === 'include') {
        relatedTerritories = relatedTerritories + item.pr_share_territory
      } else {
        if (!excludeSets.includes(item.pr_share_territory)) {
          excludeSets.push(item.pr_share_territory)
        }
      }
      if (item.sr_include_exclude === 'include') {
        if (relatedTerritories !== '') {
          relatedTerritories = relatedTerritories + ','
        }
        relatedTerritories = relatedTerritories + item.sr_share_territory
      } else {
        if (!excludeSets.includes(item.sr_share_territory)) {
          excludeSets.push(item.sr_share_territory)
        }
      }
      if (item.mr_include_exclude === 'include') {
        if (relatedTerritories !== '') {
          relatedTerritories = relatedTerritories + ','
        }
        relatedTerritories = relatedTerritories + item.mr_share_territory
      } else {
        if (!excludeSets.includes(item.mr_share_territory)) {
          excludeSets.push(item.mr_share_territory)
        }
      }
    })
  }
  var filteredDetailOptions = []
  detailOptions.map((option) => {
    if (relatedTerritories.includes(option.value) || option.value === 'WW') {
      filteredDetailOptions.push(option);
    }
  })
  excludeSets.map((set) => {
    filteredDetailOptions.push({ value: 'WW' + set, label: 'Worldwide excl. (' + set + ')' })
  })

  let scope = authStore.scope ? authStore.scope : 'exp-read exp-write exp-admin'

  if (ownerships === undefined) {
    return null
  }

  let expPlatforms = []

  if (record.exploration_platforms !== undefined && record.exploration_platforms !== null) {
    record.exploration_platforms.map((platform, idx) => {
      expPlatforms.push(
        (PLATFORMS.find((p) => p.value === platform)).label
      )
    })
  }

  const setExport = () => {
    if (!toggleExport) {
      setShowTable(false)
      setToggleExport(true)
    } else {
      setShowTable(true)
      setToggleExport(false)
    }
  }

  const bulkItems = [
    {
      name: 'Exploration Format',
      submenu: [
        {
          name: 'Shares',
          action: getExport,
          params: 'expshares'
        },
        {
          name: 'Recordings',
          action: getExport,
          params: 'exprecordings'
        },
        {
          name: 'Both',
          action: getExport,
          params: 'expsharesrecordings'
        },
      ]
    },
    {
      name: 'YouTube Format',
      action: getExport,
      params: 'youtube',
      label: 'Export'
    },
    {
      name: 'SubPublishing Format',
      action: getExport,
      params: 'subpublishing',
      label: 'Export'
    },
    {
      name: 'BMI SE Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'bmiseexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'bmiseclient'
        }
      ]
    },
    {
      name: 'BMI Admin Format',
      action: getExport,
      params: 'bmiadmin',
      label: 'Export'
    },
    {
      name: 'BMI Original Format',
      action: getExport,
      params: 'bmioriginal',
      label: 'Export'
    },
    {
      name: 'MusicMark Format',
      action: getExport,
      params: 'musicmark',
      label: 'Export'
    },
    {
      name: 'MRI Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mriexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mriclient'
        }
      ]
    },
    {
      name: 'HFA V1 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfaexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfaclient'
        }
      ]
    },
    {
      name: 'HFA V2 Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'hfafullexp'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'hfafullclient'
        }
      ]
    },
    {
      name: 'MLC Format', 
      submenu: [
        {
          name: 'Exploration',
          action: getExport,
          params: 'mlcexploration'
        },
        {
          name: 'Client',
          action: getExport,
          params: 'mlcclient'
        }
      ]
    },
    {
      name: 'CMRRA Format', 
      submenu: [
        {
          name: 'V1',
          action: getExport,
          params: 'cmrrav1'
        },
        {
          name: 'V2',
          action: getExport,
          params: 'cmrrav2'
        }
      ]
    },
    {
      name: 'LyricFind Format',
      action: getExport,
      params: 'lyricfind',
      label: 'Export'
    },
    {
      name: 'Shelly Bay Format',
      action: getExport,
      params: 'shellybay',
      label: 'Export'
    }
  ]

  const navigation = [
    { name: 'Details', href: '#nav-details'},
    { name: 'Ownerships', href: '#nav-ownerships' },
    { name: 'Notes', href: '#nav-notes' },
    { name: 'Activities', href: '#nav-activities' }
  ]

  return (<>
    <FormProvider {...methods}>
      <div className='flex flex-wrap flex-1 min-h-full'>
        <div className='p-6 flex-1'>
          {showTitleEditForm
            ? (<form onSubmit={methods.handleSubmit(formatData)}>
                <TitleForm
                  initialValues={initialValues}
                  showTitleEditForm={showTitleEditForm}
                  setShowTitleEditForm={setShowTitleEditForm}
                />
              </form>)
            : <TitleBox
              record={record}
              setRecord={setRecord}
              _toggleTitleEdit={_toggleTitleEdit}
              setExport={setExport}
              composition={record.composition}
              email={email}
              scope={scope}
            />
          }

          {!showTitleEditForm &&
            <ExplorationDetails
              record={record}
              showTitleEditForm={showTitleEditForm}
              expPlatforms={expPlatforms}
            />
          }

          <nav className="hidden lg:py-2 lg:flex lg:space-x-8 bg-white shadow sm:rounded-md" aria-label="Global">
            <div className="ml-3">
              {navigation.map((item) => (
                <button
                  type='button'
                  key={item.name}
                  className={item.name === currNav 
                    ? 'bg-white py-2 px-4 mr-2 border-red-600 text-gray-900 inline-flex items-center border-b-2 text-sm font-medium'
                    : 'bg-white py-2 px-4 mr-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                  }
                  onClick={() => setCurrNav(item.name)}
                  aria-current={item.name === currNav ? 'page' : undefined}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </nav>
          <hr />
          <div className='bg-white shadow overflow-hidden sm:rounded-md tab-content' id='nav-tabContent'>
            {currNav === 'Details' &&
              (<>
                { showEditForm
                  ? <form onSubmit={methods.handleSubmit(formatData)}>
                    <EditAdminForm
                      handleCancel={_toggleEdit}
                      setShowEditForm={setShowEditForm}
                    />
                  </form>
                  : <TableCodes
                    record={record}
                    _toggleEdit={_toggleEdit}
                  />
                }
              </>)       
            }

            {currNav === 'Ownerships' &&
              (<>
                {showOwnershipForm
                  ? <div className='py-3 px-6 mb-5 bg-white rounded-md shadow'>
                      <form onSubmit={methods.handleSubmit(formatData)} className=''>
                        <EditCollectorTable
                          showOwnershipForm={showOwnershipForm}
                          setShowOwnershipForm={setShowOwnershipForm}
                          userTerritoryPresets={userTerritoryPresets}
                        />
                      </form>
                  </div>
                  : <CollectorTable
                    showTable={showTable}
                    setRecord={setRecord}
                    showOwnershipForm={showOwnershipForm}
                    toggleOwnershipEdit={_toggleOwnershipEdit}
                    record={record}
                    expPlatforms={expPlatforms}
                    territory={excludeSets.slice(-1)[0] ? 'WW' + excludeSets.slice(-1)[0] : territory}
                    administrators={administrators}
                    ownerships={ownerships}
                  />
                }
              </>)
            }  
            {currNav === 'Notes' &&
              (<div className='tab-pane tab-pad fade' id='nav-notes' role='tabpanel' aria-labelledby='nav-notes-tab'>
                <div className='tab-wrapper'>
                  <NoteBox model={'collection_share'} pk={record.external_id} />
                </div>
              </div>)
            }
            {currNav === 'Activities' &&
              ( <div className='tab-pane tab-pad fade' id='nav-activities' role='tabpanel' aria-labelledby='nav-activities-tab'>
                <div className='tab-wrapper'>
                  <ActionFeed
                    model={'collection_share'}
                    pk={id} />
                </div>
              </div>)
            }
          </div>
        </div>
        { toggleExport &&
          (<div className="flex-grow-0 flex-shrink-0">
            <BulkOptions items={bulkItems} onClose={setExport} />
          </div>)
        }
      </div>
    </FormProvider>
  </>)
}

export default AdminDetail
