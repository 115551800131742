import React from 'react'
import { Link } from 'react-router-dom'
import RoleCode from 'layouts/components/RoleCode'
import { PlusCircleIcon } from '@heroicons/react/outline'

const ShareTable = ({ record, adminshares }) => {
  return (<>
    <div className='tab-actions _right'>
      <div className='dropdown'>
        <a href={'/shares/add?composition=' + record.external_id} className='inline-flex items-center px-4 py-2 border border-transparent
          shadow-sm text-sm font-medium rounded-md text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none'>
          Link New Share<PlusCircleIcon className="ml-2 h-4 w-4" aria-hidden="true" />
        </a>
      </div>
    </div>
    <div className="flex flex-col">
      <div className="mb-5 px-4 py-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Collector
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Writer
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sync.
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sync. Territory
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Mech.
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Mech. Territory
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Perf.
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Perf. Territory
                  </th>
                  {adminshares[0] && adminshares[0].total_lr_share && 
                    (<th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Lyric
                    </th>)
                  }
                  {adminshares[0] && adminshares[0].total_lr_share && 
                    (<th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Lyric Territory
                    </th>)
                  }
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {adminshares.map((item, shareIdx) => (
                  <tr key={item.external_id} className={(item.exploration_platforms && item.exploration_platforms.length === 0) ? 'bg-gray-100' : 'bg-white'}>
                    <td className="px-3 py-4 text-sm font-medium">
                      <Link className="hover:text-blue-500" to={`/shares/${item.external_id}/`}>{item.title}</Link>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {item.collectors.map((collector, idx) => {
                        return (
                          <a className="block mb-1 hover:text-blue-500" key={idx} href={`/${item.collector_set_type}s/` + `${collector['external_id']}`}>{collector['name']}<br /></a>
                        )
                      })}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">{item.writers.map((obj, idx) => {
                      return (
                        <div key={'A' + idx + obj.pk}>
                          {obj.name !== undefined ? (
                            <div>
                              <a className="block mb-1 hover:text-blue-500" href={'/writers/' + obj.external_id + '/'}>
                                {obj.name}
                                {obj.writer_role_code !== null
                                  ? <RoleCode roleCode={obj.writer_role_code} asBadge />
                                  : null}
                              </a>
                            </div>
                            ) : null
                          }
                        </div>
                      )
                    })}</td>
                    {item.nextone_total
                      ? (<>
                        <td className="px-3 py-4 text-sm text-gray-500">{item.nextone_total.toString().split('.')[1] !== undefined &&
                            item.nextone_total.toString().split('.')[1].length > 4
                          ? (parseFloat(item.nextone_total).toFixed(2))
                          : item.nextone_total}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">{item.sr_share_territory.replace(/,/g, ' ')}</td>
                        <td className="px-3 py-4 text-sm text-gray-500">{item.nextone_total.toString().split('.')[1] !== undefined &&
                            item.nextone_total.toString().split('.')[1].length > 4
                          ? (parseFloat(item.nextone_total).toFixed(2))
                          : item.nextone_total}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">{item.mr_share_territory.replace(/,/g, ' ')}</td>
                        <td className="px-3 py-4 text-sm text-gray-500">{item.nextone_total.toString().split('.')[1] !== undefined &&
                            item.nextone_total.toString().split('.')[1].length > 4
                          ? (parseFloat(item.nextone_total).toFixed(2)/2)
                          : parseFloat(item.nextone_total)/2}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">{item.pr_share_territory.replace(/,/g, ' ')}</td>
                        {item.exploration_platforms.includes('LyricFind') &&
                          (<>
                            <td className="px-3 py-4 text-sm text-gray-500">{item.nextone_total.toString().split('.')[1] !== undefined &&
                                item.nextone_total.toString().split('.')[1].length > 4
                              ? (parseFloat(item.nextone_total).toFixed(2))
                              : item.nextone_total}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500">{item.lr_share_territory.replace(/,/g, ' ')}</td>
                          </>)
                        }
                      </>)
                      : <>
                        {item.total_sr_share > 100
                          ? <td className="px-3 py-4 text-sm text-gray-500" style={{ color: 'red' }}>
                            {item.total_sr_share.toString().split('.')[1] !== undefined &&
                              item.total_sr_share.toString().split('.')[1].length > 4
                            ? (parseFloat(item.total_sr_share).toFixed(2))
                            : item.total_sr_share}
                          </td>
                          : <td className="px-3 py-4 text-sm text-gray-500">{item.total_sr_share.toString().split('.')[1] !== undefined &&
                              item.total_sr_share.toString().split('.')[1].length > 4
                            ? (parseFloat(item.total_sr_share).toFixed(2))
                            : item.total_sr_share}
                          </td>
                        }
                        <td className="px-3 py-4 text-sm text-gray-500">{item.sr_share_territory.replace(/,/g, ' ')}</td>
                        {item.total_mr_share > 100
                          ? <td className="px-3 py-4 text-sm text-gray-500" style={{ color: 'red' }}>
                            {item.total_mr_share.toString().split('.')[1] !== undefined &&
                              item.total_mr_share.toString().split('.')[1].length > 4
                            ? (parseFloat(item.total_mr_share).toFixed(2))
                            : item.total_mr_share}
                          </td>
                          : <td className="px-3 py-4 text-sm text-gray-500">{item.total_mr_share.toString().split('.')[1] !== undefined &&
                              item.total_mr_share.toString().split('.')[1].length > 4
                            ? (parseFloat(item.total_mr_share).toFixed(2))
                            : item.total_mr_share}
                          </td>
                        }
                        <td className="px-3 py-4 text-sm text-gray-500">{item.mr_share_territory.replace(/,/g, ' ')}</td>
                        {item.total_pr_share > 100
                          ? <td className="px-3 py-4 text-sm text-gray-500" style={{ color: 'red' }}>
                            {item.total_pr_share.toString().split('.')[1] !== undefined &&
                              item.total_pr_share.toString().split('.')[1].length > 4
                            ? (parseFloat(item.total_pr_share).toFixed(2))
                            : item.total_pr_share}
                          </td>
                          : <td className="px-3 py-4 text-sm text-gray-500">{item.total_pr_share.toString().split('.')[1] !== undefined &&
                              item.total_pr_share.toString().split('.')[1].length > 4
                            ? (parseFloat(item.total_pr_share).toFixed(2))
                            : item.total_pr_share}
                          </td>
                        }
                        <td className="px-3 py-4 text-sm text-gray-500">{item.pr_share_territory.replace(/,/g, ' ')}</td>
                        {item.total_lr_share && item.total_lr_share > 100
                          ? <td className="px-3 py-4 text-sm text-gray-500" style={{ color: 'red' }}>
                            {item.total_lr_share.toString().split('.')[1] !== undefined &&
                              item.total_lr_share.toString().split('.')[1].length > 4
                            ? (parseFloat(item.total_lr_share).toFixed(2))
                            : item.total_lr_share}
                          </td>
                          : item.total_lr_share && item.total_lr_share < 100
                          ? <td className="px-3 py-4 text-sm text-gray-500">{item.total_lr_share.toString().split('.')[1] !== undefined &&
                                item.total_lr_share.toString().split('.')[1].length > 4
                              ? (parseFloat(item.total_lr_share).toFixed(2))
                              : item.total_lr_share}
                            </td>
                            : null
                        }
                        {item.lr_share_territory && <td className="px-3 py-4 text-sm text-gray-500">{item.lr_share_territory.replace(/,/g, ' ')}</td>}
                      </>
                    }
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default ShareTable