import React, { useState } from 'react'
import styled from 'styled-components'
import { DateRange } from 'react-date-range'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Label = styled.label`
  font-size: 12px;
  margin-bottom: 5px;
`

const SearchBox = styled.div`
  .btn {
    margin: 0 !important;
  }
`

const DateBox = styled.div`
  margin-top: 10px;
`

const StyledDateRange = styled(DateRange)`
  width: 100%;
`

const Wrapper = styled.div`
  padding-bottom: 80px;
`

const FormActions = styled.div`
  .btn {
    margin-right: 10px;
  }
`

const PLATFORMS = [
  { value: 'youtube', label: 'YouTube' },
  { value: 'mri', label: 'MRI' },
  { value: 'hfa', label: 'HFA' },
  { value: 'cmrra', label: 'CMRRA' },
  { value: 'ascap', label: 'ASCAP' },
  { value: 'bmi', label: 'BMI' },
  { value: 'sesac', label: 'SESAC' },
  { value: 'soundexchange', label: 'SoundExchange' },
  { value: 'distribution', label: 'Distribution' },
  { value: 'sub-publishing', label: 'Sub-Publishing' },
  { value: 'mlc', label: 'MLC' },
  { value: 'lyricfind', label: 'LyricFind' },
  { value: 'lyricfindmerch', label: 'LyricFindMerch' },
  { value: 'direct licensing', label: 'Direct Licensing' },
  { value: 'sync', label: 'Sync' }
]

const UPDATE = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]

function Filters ({ searchChange, dateChange, platformChange, readyChange, updateChange, handleFilter, handleReset, loading }) {
  const [searchFilter, setSearchFilter] = useState()
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [platformFilter, setPlatformFilter] = useState()
  const [platformReady, setPlatformReady] = useState(false)
  const [isUpdate, setIsUpdate] = useState()

  const handleSearchFilter = (evt) => {
    setSearchFilter(evt.target.value)
    searchChange(evt.target.value)
  }

  const handleDateChange = (data) => {
    setDateFilter(data)
    dateChange(data)
  }

  const handlePlatformChange = (data) => {
    let platformValues = []
    data.forEach(platform => platformValues.push(platform.value))
    setPlatformFilter(data)
    platformChange(platformValues)
  }

  const handleReadyChange = (data) => {
    setPlatformReady(data)
    readyChange(data)
  }

  const handleUpdateChange = (data) => {
    setIsUpdate(data)
    updateChange(data.value)
  }

  const _handleReset = () => {
    setDateFilter([{
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }])
    setSearchFilter('')
    setPlatformFilter([])
    setPlatformReady(false)
    setIsUpdate({})
    handleReset()
  }

  return (<Wrapper>
    <Form>
      <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
        <div className='sm:col-span-3'>
          <DateBox>
            <label htmlFor="uploadDate" className="block text-sm font-medium text-gray-700">
              Upload Date
            </label>
            <input type='hidden' name='startDate' />
            <input type='hidden' name='endDate' />
            <StyledDateRange
              className='mt-1'
              editableDateInputs
              onChange={item => handleDateChange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </DateBox>
        </div>
        <div className='sm:col-span-3 space-y-6 sm:space-y-5'>
          <div className='sm:col-span-3'>
            <label htmlFor="filename" className="block text-sm font-medium text-gray-700">
                Search by filename
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='filename'
                className='block max-w-lg w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
                value={searchFilter}
                onChange={handleSearchFilter}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Update?
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Was the ingest an update to existing assets?
                </Switch.Description>
              </span>
              <Switch
                checked={isUpdate}
                onChange={setIsUpdate}
                className={classNames(
                  isUpdate ? 'bg-red-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    isUpdate ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>

          <div className='sm:col-span-3'>
            <label htmlFor="platforms" className="block text-sm font-medium text-gray-700">
              Search by platform
            </label>
            <Select
              className='mt-1 shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md'
              options={PLATFORMS}
              value={platformFilter}
              onChange={handlePlatformChange}
              isMulti
            />
          </div>

          <div className="sm:col-span-3">
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  Platforms Ready?
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Are the platforms listed above ready?
                </Switch.Description>
              </span>
              <Switch
                checked={platformReady}
                onChange={setPlatformFilter}
                className={classNames(
                  platformReady ? 'bg-red-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    platformReady ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => _handleReset()}
                disabled={loading}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Reset
              </button>
              <button
                onClick={() => handleFilter()}
                disabled={loading}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </Wrapper>)
}

export default Filters
