import React from 'react'
import { useFormContext } from 'react-hook-form'
import ReactTooltip from 'react-tooltip'

const ComputedShareTable = ({ expPlatforms, showOwnershipForm, showPercentageForm, record, withAdmin }) => {
  const { register } = useFormContext()
  console.log(record)

  const totalPr = record.nextone_total ? record.nextone_total/2 : record.total_pr
  const totalMr = record.nextone_total ? record.nextone_total : record.total_mr
  const totalSr = record.nextone_total ? record.nextone_total : record.total_sr
  const totalLr = record.nextone_total ? record.nextone_total : record.total_lr

  const srShareTerritories = record.sr_share_territory.replace(/,/g, ' ')
  const mrShareTerritories = record.mr_share_territory.replace(/,/g, ' ')
  const prShareTerritories = record.pr_share_territory.replace(/,/g, ' ')
  const lrShareTerritories = record.lr_share_territory.replace(/,/g, ' ')

  const collectors = record.collectors
  const collectorRoleCode = record.collector_role_code

  const option = {}
  option['AD'] = 'Adaptor'
  option['AM'] = 'Administrator'
  option['AR'] = 'Arranger'
  option['PR'] = 'Associated Performer'
  option['A'] = 'Author'
  option['C'] = 'Composer'
  option['CA'] = 'Composer/Author'
  option['E'] = 'Original Publisher'
  option['PA'] = 'Publisher Income Participant'
  option['SR'] = 'Sub-Arranger'
  option['SA'] = 'Sub-Author'
  option['SE'] = 'Sub-Publisher'
  option['ES'] = 'Substitute Publisher'
  option['TR'] = 'Translator'
  option['W'] = 'Writer'
  option['PY'] = 'Payee'

  return (
    collectors ? <>
      {!showOwnershipForm
      ? <>
        <td className="px-3 py-4 text-sm font-medium">
          { collectors.map((obj, idx) => {
            return (
              <span key={'A' + idx + obj.pk}>
                <span>
                  <a className="block mb-1 hover:text-blue-500"  href={`/${record.collector_set_type}s/` +
                    `${obj.external_id}`
                  }>{obj.name}{' '}</a>
                </span>
              </span>
            )
          })
          }
        </td>
        { withAdmin ? <td /> : null }
        <td />
        <td className="px-3 py-4 text-sm text-gray-500">
          <span data-tip data-for='collectorRoleCode' className='form-tip text-gray-500'>
            {collectorRoleCode}
          </span>
          <ReactTooltip id='collectorRoleCode' type='dark' place='top'>
            <span>{option[collectorRoleCode]}</span>
          </ReactTooltip>
        </td>
        <td />
      </>
      : null}
      {showPercentageForm
        ? <>
          <td className="px-3 py-4 text-sm text-gray-500">
            <input
              type='number'
              step='.01'
              min='0'
              max='100'
              className='shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
              {...register('total_sr', { min: 0, max: 100 })}
            />
          </td>
          <td className="px-3 py-4 text-sm text-gray-500">{srShareTerritories}</td>
          <td>
            <input
              type='number'
              step='.01'
              min='0'
              max='100'
              className='shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
              {...register('total_mr', { min: 0, max: 100 })}
            />
          </td>
          <td className="px-3 py-4 text-sm text-gray-500">{mrShareTerritories}</td>
          <td className="px-3 py-4 text-sm text-gray-500">
            <input
              type='number'
              step='.01'
              min='0'
              max='100'
              className='shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
              {...register('total_pr', { min: 0, max: 100 })}
            />
          </td>
          <td className="px-3 py-4 text-sm text-gray-500">{prShareTerritories}</td>
          {expPlatforms.includes('LyricFind') &&
            (<td className="px-3 py-4 text-sm text-gray-500">
              <input
                type='number'
                step='.01'
                min='0'
                max='100'
                className='shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm border-gray-300 rounded-md'
                {...register('total_lr', { min: 0, max: 100 })}
              />
            </td>)
          }
          {expPlatforms.includes('LyricFind') &&
            (<td className="px-3 py-4 text-sm text-gray-500">{lrShareTerritories}</td>)
          }
        </>
        : <>
          <td className="px-3 py-4 text-sm text-gray-500">{totalSr}</td>
          <td className="px-3 py-4 text-sm text-gray-500">{srShareTerritories}</td>
          <td className="px-3 py-4 text-sm text-gray-500">{totalMr}</td>
          <td className="px-3 py-4 text-sm text-gray-500">{mrShareTerritories}</td>
          <td className="px-3 py-4 text-sm text-gray-500">{totalPr}</td>
          <td className="px-3 py-4 text-sm text-gray-500">{prShareTerritories}</td>
          {expPlatforms.includes('LyricFind') && <td className="px-3 py-4 text-sm text-gray-500">{totalLr}</td>}
          {expPlatforms.includes('LyricFind') && <td className="px-3 py-4 text-sm text-gray-500">{lrShareTerritories}</td>}
        </>
      }
    </> : null
  )
}

export default ComputedShareTable
